import VueRouter from "vue-router";
import store from "@/store";
import Cookie from "js-cookie";

// this change is only for title fields
let whitelabel = process.env.VUE_APP_WHITELABEL;
if (whitelabel === "TD365") {
  whitelabel = "TradeDirect365";
}
if (whitelabel === "TDSouthAfrica") {
  whitelabel = "TD365";
}

const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout.vue"),
    meta: {
      requiresAuth: true,
      title: `${whitelabel}`,
      name: "Home",
    },
    redirect: redirect(),
    children: [
      {
        path: "/",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Content"),

        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Account summary`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "AccountSummary",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/trading-accounts/AccountSummary.vue"
              ),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Account summary`,
            },
          },
        ],
      },
      {
        path: "/account-summary",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Account summary`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "AccountSummary",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/trading-accounts/AccountSummary.vue"
              ),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Account summary`,
            },
          },
          {
            path: "/open-live-account",
            name: "OpenLiveAccount",
            beforeEnter: checkStateFull,
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/trading-accounts/OpenLiveAccount"
              ),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Open Live Account`,
            },
          },
          {
            path: "/add-new-live-account",
            name: "AddLiveAccount",
            // beforeEnter: checkStateFull,
            beforeEnter(to, from, next) {
              if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
                next();
              } else {
                next({
                  path: "/not-authorized",
                  replace: true,
                });
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/trading-accounts/AddLiveAccount"
              ),
            meta: {
              requiresAuth: true,
              title: `${process.env.VUE_APP_WHITELABEL} - Add new live account`,
            },
          },
          {
            path: "/add-new-demo-account",
            name: "AddDemoAccount",
            beforeEnter(to, from, next) {
              if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
                next();
              } else {
                next({
                  path: "/not-authorized",
                  replace: true,
                });
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/trading-accounts/AddDemoAccount"
              ),
            meta: {
              requiresAuth: true,
              title: `${process.env.VUE_APP_WHITELABEL} - Add new demo account`,
            },
          },
        ],
      },
      {
        path: "/personal-information",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Personal Information`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "Details",
            component: () =>
              import("../views/documents/profile-details/Details"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Personal Information`,
            },
          },
        ],
      },
      {
        path: "/legal-documents",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Legal Documents`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "LegalDocuments",
            component: () =>
              import("../views/documents/legal-documents/LegalDocuments"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Legal Documents`,
              permissions: ["Client"],
            },
          },
        ],
      },
      {
        path: "/upload-documents",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        beforeEnter(to, from, next) {
          if (Cookie.get("state") !== "Demo") {
            next();
          } else {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        },

        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Upload Documents`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "UploadDocuments",
            component: () =>
              import("../views/documents/upload-documents/UploadDocuments"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Upload Documents`,
            },
          },
        ],
      },
      {
        path: "/payment-history",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter(to, from, next) {
          next();
        },
        component: () => import("../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Payments`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "PaymentHistory",
            component: () => import("../views/payments/PaymentHistory"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Payment History`,
            },
          },
        ],
      },
      {
        path: "/deposit-funds",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        // beforeEnter: checkState,
        beforeEnter: (to, from, next) => {
          if (Cookie.get("state") !== "Demo") {
            next();
          } else {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        },
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Deposit funds`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "DepositFunds",
            component: () => {
              return import("../views/payments/deposit/DepositFunds");
            },
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Deposit funds`,
            },
          },
        ],
      },
      //--------------------new router page----------#BR#26-Mbeng Atemson-3/2/2025---------------
      {
        path: "/deposit-usdt",
        component: () => import("../views/Content"), 
        
        beforeEnter: (to, from, next) => {
          // Check if the user's state is not "Demo"
          if (Cookie.get("state") !== "Demo") {
            next(); // Allow navigation
          } else {
            next({
              path: "/not-authorized",
              replace: true, 
            });
          }
        },
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Deposit USDT`, 
          permissions: ["Client"], 
        },
        children: [
          {
            path: "",
            name: "DepositUSDT",
            component: () => import("../views/payments/deposit/DepositModal"),
            props: route => ({
              isVisible: true,
              amount: route.query.amount, 
              
            }),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Deposit USDT`,
            },
          },
        ],
      },

//-------------------------end---------------------
      {
        path: "/internal-transfer",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        beforeEnter: checkState,
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Internal Transfer`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "InternalTransfer",
            component: () => import("../views/payments/InternalTransfer"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Internal Transfer`,
            },
          },
        ],
      },
      {
        path: "/analysis",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter(to, from, next) {
          if (
            process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
            process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
            process.env.VUE_APP_WHITELABEL === "TradiNext"
          ) {
            next({
              path: "/not-authorized",
              replace: true,
            });
          } else {
            next();
          }
        },
        component: () => import("../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Analysis`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "Analysis",
            component: () => import("../views/payments/Analysis"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Analysis`,
            },
          },
        ],
      },
      {
        path: "/brokeree",
        beforeEnter(to, from, next) {
          if (process.env.VUE_APP_WHITELABEL === "GCCBrokers") {
            next();
          } else {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        },
        component: () => import("../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Brokeree`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "Brokeree",
            component: () => import("../views/payments/Brokeree"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Brokeree`,
            },
          },
        ],
      },
      {
        path: "/bank-details",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        beforeEnter(to, from, next) {
          if (Cookie.get("state") !== "Demo") {
            next();
          } else {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        },
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Bank Details`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "BankDetails",
            component: () =>
              import("../views/payments/bank-details/BankDetails"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Bank Details`,
            },
          },
        ],
      },
      {
        path: "/withdraw-funds",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        beforeEnter(to, from, next) {
          if (Cookie.get("state") !== "Demo") {
            next();
          } else {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        },
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Withdraw funds`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "WithdrawFunds",
            component: () => import("../views/payments/WithdrawFunds"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Withdraw funds`,
            },
          },
        ],
      },
      {
        path: "/apply-for-mastercard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        // beforeEnter: checkState,
        beforeEnter: (to, from, next) => {
          if (
            process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
            process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
            process.env.VUE_APP_WHITELABEL === "ICMMENA" ||
            process.env.VUE_APP_WHITELABEL === "TradiNext" ||
            process.env.VUE_APP_WHITELABEL === "ICMCapital"
          ) {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
          if (process.env.VUE_APP_WHITELABEL === "ICMTrader") {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
          // check if the user account is Full
          if (Cookie.get("state") !== "Demo") {
            next();
          } else {
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        },

        meta: {
          requiresAuth: true,
          title: `${whitelabel} - Apply for Mastercard`,
          permissions: ["Client"],
        },
        children: [
          {
            path: "",
            name: "ApplyForMastercard",
            component: () => import("../views/payments/ApplyForMastercard"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - Apply for Mastercard`,
            },
          },
        ],
      },
      {
        path: "/ib",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Content"),
        meta: {
          requiresAuth: true,
          title: `${whitelabel} - IB`,
          permissions: ["IB"],
        },
        redirect: "/ib/summary",
        children: [
          {
            path: "/ib/summary",
            name: "Summary",
            component: () => import("../views/ib/trading/Summary"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Summary`,
              permissions: ["IB"],
            },
          },
          {
            path: "/ib/tracking-links",
            name: "TrackingLinks",
            component: () =>
              import("../views/ib/trading/tracking-links/Tracking-links"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Tracking links`,
              permissions: ["IB"],
            },
          },
          {
            path: "/ib/client-list",
            name: "ClientList",
            component: () =>
              import("../views/ib/trading/client-list/ClientList"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Client List`,
              permissions: ["IB"],
            },
          },
          // {
          //     path: '/ib/reports',
          //     name: 'Reports',
          //     component: () => import('../views/ib/trading/Reports'),
          //     meta: {
          //         requiresAuth: true,
          //         title: `${whitelabel} - IB - Client List`,
          //         permissions: ['IB']
          //     }
          // },
          {
            path: "/ib/upload-documents",
            name: "IbUploadDocuments",
            component: () => import("../views/ib/documents/UploadDocuments"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Client List`,
              permissions: ["IB"],
            },
          },
          {
            path: "/ib/payments-history",
            name: "IbPaymentHistory",
            component: () => import("../views/ib/payments/PaymentHistory"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Payment History`,
              permissions: ["IB"],
            },
          },
          {
            path: "/ib/request-payment",
            name: "RequestPayment",
            component: () => import("../views/ib/payments/RequestPayment"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Request payment`,
              permissions: ["IB"],
            },
          },
          {
            path: "/ib/pending-withdrawals",
            name: "PendingWithdrawals",
            component: () => import("../views/ib/payments/PendingWithdrawals"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Pending Withdrawals`,
            },
          },
          {
            path: "/ib/personal-details",
            name: "IB-Details",
            component: () => import("../views/ib/profile/Details"),
            meta: {
              requiresAuth: true,
              title: `${whitelabel} - IB - Personal Details`,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/auth/login",
    component: () => import("../layouts/BlankLayout.vue"),
    beforeEnter: checkIfUserIsLogged,
    meta: {
      guest: true,
    },
    children: [
      {
        path: "",
        name: "Login",
        meta: {
          title: `${whitelabel} - Login`,
        },
        component: () => import("../views/Login.vue"),
      },
      // {
      //     path: '/demo',
      //     name: 'demo',
      //     meta: {
      //         title: `${whitelabel} - Registration`
      //     },
      //     component: () => import(/* webpackChunkName: "about" */ '../views/register/Register.vue')
      // },
      // {
      //     path: process.env.VUE_APP_WHITELABEL === 'ICMTrader' ? '/demo:pathMatch(.*)*' : '/accounts:pathMatch(.*)*',
      //     name: 'accounts',
      //     meta: {
      //         title: `${whitelabel} - Registration`
      //     },
      //     component: () => import(/* webpackChunkName: "about" */ '../views/register/Register.vue')
      // },
      // {
      //     path: process.env.VUE_APP_WHITELABEL !== 'ICMTrader' ? '/demo:pathMatch(.*)*' ,
      //     redirect: '/accounts'
      // },
      {
        path: "/demo:pathMatch(.*)*",
        beforeEnter: checkTrackingLink,
        // redirect: '/accounts'
      },
      {
        path: "/accounts",
        name: "accounts",
        meta: {
          title: `${whitelabel} - Registration`,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/register/Register.vue"
          ),
      },
      {
        path: "/individual-register",
        name: "individual-demo-register",
        beforeEnter(to, from, next) {
          if (
            process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
            process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
            process.env.VUE_APP_WHITELABEL === "PolarisMarkets"
          ) {
            next();
          } else {
            next({
              path: "/accounts",
              replace: true,
            });
          }
        },
        meta: {
          title: `${whitelabel} - Register`,
        },
        component: () => import("../views/register/RegisterIndividual"),
      },
      {
        path: "/company-register",
        name: "company-demo-register",
        beforeEnter(to, from, next) {
          if (
            process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
            process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
            process.env.VUE_APP_WHITELABEL === "PolarisMarkets"
          ) {
            next();
          } else {
            next({
              path: "/accounts",
              replace: true,
            });
          }
        },
        meta: {
          title: `${whitelabel} - Register`,
        },
        component: () => import("../views/register/RegisterCompany"),
      },
      // {
      //     path: '/demo:pathMatch(.*)*',
      //     name: 'accounts',
      //     meta: {
      //         title: `${whitelabel} - Registration`
      //     },
      //     component: () => import(/* webpackChunkName: "about" */ '../views/register/Register.vue')
      // },
      {
        path: "/forgot",
        meta: {
          title: `${whitelabel} - Forgot Password`,
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ForgotPassword.vue"),
      },
    ],
  },
  {
    path:
      process.env.VUE_APP_WHITELABEL === "ICMCapital"
        ? "/access:pathMatch(.*)*"
        : "/null",
    redirect: "/auth/login",
  },
  {
    path:
      process.env.VUE_APP_WHITELABEL === "Promena"
        ? "/access:pathMatch(.*)*"
        : "/null",
    redirect: "/ib/login",
  },
  {
    path:
      process.env.VUE_APP_WHITELABEL === "OneTradingMarkets"
        ? "/live-account"
        : "/null",
    redirect: "/accounts",
  },
  {
    path:
      process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
      process.env.VUE_APP_WHITELABEL === "TradeCoreUK" ||
      process.env.VUE_APP_WHITELABEL === "TD365" ||
      process.env.VUE_APP_WHITELABEL === "TDSouthAfrica"
        ? "/step-one"
        : "/null",
    redirect: "/accounts",
  },
  {
    path: "/auth/reset-password:pathMatch(.*)*",
    meta: {
      title: `${whitelabel} - New Password`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewPassword.vue"),
  },
  {
    path: "/portal/trading-account-reset-password:pathMatch(.*)*",
    meta: {
      title: `${whitelabel} - Reset trading account password`,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ResetTradingPassword.vue"
      ),
  },
  {
    path: "/auth/widget:pathMatch(.*)*",
    name: "LoginAsUser",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/trading-accounts/LoginAsUser"
      ),
  },
  {
    path: "/welcome",
    component: () => import("../views/Welcome.vue"),
    beforeEnter(to, from, next) {
      if (
        process.env.VUE_APP_WHITELABEL === "Blackstone" &&
        from.path === "/accounts"
      ) {
        next();
      } else {
        next({
          path: "/not-authorized",
          replace: true,
        });
      }
    },
    meta: {
      requiresAuth: true,
      title: `${process.env.VUE_APP_WHITELABEL}`,
      name: "Welcome",
    },
  },
  {
    path: "/ib/signup/",
    component: () => import("../layouts/BlankLayout.vue"),
    meta: {
      guest: true,
    },
    beforeEnter: checkIfUserIsLoggedIb,
    children: [
      {
        path: "",
        name: "ib-register",
        meta: {
          title: `${whitelabel} - Register`,
        },
        component: () => import("../views/ib/register/SelectTypeOfAccount"),
      },
      {
        path: "/ib/login",
        name: "login",
        meta: {
          title: `${whitelabel} - IB - Login`,
        },
        component: () => import("../views/Login"),
      },
      {
        path: "/ib/signup/individual",
        name: "individual-register",
        meta: {
          title: `${whitelabel} - Register`,
        },
        component: () => import("../views/ib/register/IndividualRegister"),
      },
      {
        path: "/ib/signup/company",
        name: "company-register",
        meta: {
          title: `${whitelabel} - Register`,
        },
        component: () => import("../views/ib/register/CompanyRegister"),
      },
      {
        path: "/ib/signup:pathMatch(.*)*",
        name: "ib-register-select-type-of-account",
        meta: {
          title: `${whitelabel} - Registration`,
        },
        component: () => import("../views/ib/register/SelectTypeOfAccount"),
      },
      {
        path: "/ib/forgot",
        meta: {
          title: `${whitelabel} - Forgot Password`,
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/lead/",
    component: () => import("../layouts/BlankLayout.vue"),
    meta: {
      guest: true,
    },
    children: [
      {
        path: "",
        name: "lead",
        meta: {
          title: `${whitelabel} - Lead`,
        },
        component: () => import("../views/lead/LeadForms"),
      },
    ],
  },
  {
    path: "/mobile/sign-in",
    component: () => import("../layouts/BlankLayout.vue"),
    beforeEnter: mobileCheck,
    meta: {
      guest: true,
    },
    children: [
      {
        path: "",
        name: "LoginMobile",
        meta: {
          title: `${whitelabel} - Login`,
        },
        component: () => import("../views/LoginMobile.vue"),
      },
      {
        path: "/mobile/forgot",
        meta: {
          title: `${whitelabel} - Forgot Password`,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ForgotPasswordMobile.vue"
          ),
      },
      {
        path: "/mobile/sign-up",
        name: "MobileSingup",
        meta: {
          title: `${whitelabel} - Registration`,
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/register/RegisterMobile.vue"
          ),
      },
    ],
  },
  {
    /* this has to be the last route in the list (any route after this wont work)*/
    path: "/:catchAll(.*)",
    meta: {
      title: `${whitelabel}`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error404"),
  },
];

const router = new VueRouter({
  mode: "history",
  // scrollBehavior() {
  //     document.getElementById('router-view').scrollIntoView();
  // },
  routes,
});

function mobileCheck(to, from, next) {
  if (
    process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
    process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
  ) {
    next();
  } else {
    next({
      path: "/not-authorized",
      replace: true,
    });
  }
}

function checkIfUserIsLogged(to, from, next) {
  if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
    //   redirect to external login
    const url = process.env.VUE_APP_API_URL.includes("dev.")
      ? "https://dev-website.anttix.app/auth/login"
      : "https://vcgmarkets.com/auth/login";
    return window.open(url, "_self");
  }
  if (
    process.env.VUE_APP_WHITELABEL === "Promena" ||
    process.env.VUE_APP_WHITELABEL === "ICMCapital"
  ) {
    if (process.env.VUE_APP_WHITELABEL === "ICMCapital") {
      next();
    }
    if (process.env.VUE_APP_WHITELABEL === "Promena") {
      next({
        path: "/ib/login",
        replace: true,
      });
    }
  }
  if (Cookie.get("auth_token")) {
    next({
      path: "/",
      replace: true,
    });
  } else {
    next();
  }
}

function checkIfUserIsLoggedIb(to, from, next) {
  // client requested that blackstone IB is made by them so we block access if user comes from our portal
  if (
    process.env.VUE_APP_WHITELABEL === "Promena" ||
    process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
    process.env.VUE_APP_WHITELABEL === "ICMEU" ||
    process.env.VUE_APP_WHITELABEL === "Blackstone" ||
    process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
    process.env.VUE_APP_WHITELABEL === "VCGMarkets" ||
    process.env.VUE_APP_WHITELABEL === "PolarisMarkets"
  ) {
    if (process.env.VUE_APP_WHITELABEL === "Promena") {
      if (Cookie.get("auth_token")) {
        next({
          path: "/ib/",
          replace: true,
        });
      } else {
        next();
      }
    }
    if (
      process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
      process.env.VUE_APP_WHITELABEL === "ICMEU" ||
      process.env.VUE_APP_WHITELABEL === "Blackstone" ||
      process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
      process.env.VUE_APP_WHITELABEL === "ICMEU" ||
      process.env.VUE_APP_WHITELABEL === "VCGMarkets"
    ) {
      next({
        path: "/not-authorized",
        replace: true,
      });
    }
  }
  if (Cookie.get("auth_token") && localStorage.getItem("step") !== "1") {
    next({
      path: "/ib/",
      replace: true,
    });
  } else {
    next();
  }
}

function checkState(to, from, next) {
  // check if the user account is Full
  if (process.env.VUE_APP_WHITELABEL === "OrbitInvest") {
    next({
      path: "/not-authorized",
      replace: true,
    });
  } else if (Cookie.get("state") !== "Demo") {
    next();
  } else {
    next({
      path: "/not-authorized",
      replace: true,
    });
  }
}

function checkStateFull(to, from, next) {
  // check if the user account is Full
  // if ((Cookie.get('state') === 'Demo' || Cookie.get('state') === 'Unsuitable%20Demo')) {
  //     next({
  //         path: '/not-authorized',
  //         replace: true
  //     })
  // } else {
  next();
  // }
}

function redirect() {
  if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
    return;
  }
  if (process.env.VUE_APP_WHITELABEL === "Promena") {
    return "/ib/";
  } else {
    return "/account-summary";
  }
}

router.beforeEach((to, from, next) => {
  // Redirect if path begins with a hash (ignore hashes later in path)
  // if (to.redirectedFrom && to.redirectedFrom.substr(0, 2) === "/#") {
  //   const path = to.redirectedFrom.substr(2);
  //   // eslint-disable-next-line no-console
  //   next({ path, replace: true });
  // }

  // ERR#3 - Mbeng Atemson forget password redirect link corrected - 15/08/2024 
  if (to.fullPath.includes("#")) {
    console.log("line 1062 working")
    const path = to.fullPath.replace("/#", "");
    next({ path, replace: true });
  }   
  //ERR#3 -- End --
  // Redirect for tracking links without tracing id in url
  if (to.redirectedFrom && to.redirectedFrom.substr(0, 4) === "/?t=") {
    if (to.query.hasOwnProperty("t") && !Cookie.get("x-tracking-id")) {
      Cookie.set("x-tracking-id", to.query.t);
    }
    let path;
    path = "/accounts";
    // eslint-disable-next-line no-console
    next({ path, replace: true });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
    next();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.is_logged_in) {
      if (process.env.VUE_APP_WHITELABEL === "Promena") {
        next({ name: "ib-register" });
      } else if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
        console.log("CHECKING VCG MARKETS");
        //   redirect to external login page
        const url = process.env.VUE_APP_API_URL.includes("dev.")
          ? "https://dev-website.anttix.app/auth/login"
          : "https://vcgmarkets.com/auth/login";

        return window.open(url, "_self");
      } else if (process.env.VUE_APP_WHITELABEL === "OneTradingMarkets") {
        next({
          path: "/auth/login",
          replace: true,
        });
      } else {
        next({ name: "accounts" });
      }
    } else {
      authorizationCheck(to, from, next);
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

// EGM has tracking links for demo and for live
// so when user comes from tracking link /demo/t... we set coockie information
// so we can then load the demo form instead of default live form
function checkTrackingLink(to, from, next) {
  // we check if it has tracking link
  if (to.query.t) {
    // we check if its EGM, then we need to load demo form instead of live form
    // so we set coockie 'demoLink' that we will check in register.vue
    if (
      process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
      process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
    ) {
      Cookie.set("demoLink", "yes");
      next({
        path: "/accounts",
        query: { t: to.query.t },
        replace: true,
      });
    } else {
      next({
        path: "/accounts",
        query: { t: to.query.t },
        replace: true,
      });
    }
  } else {
    next({
      path: "/accounts",
      replace: true,
    });
  }
}

function authorizationCheck(to, from, next) {
  let allowedToEnter = true;

  to.matched.some((record) => {
    // check if there is meta data

    if ("meta" in record) {
      // ------------------------------------------------------------
      // check if user has correct permissions to access this page
      if (allowedToEnter && "permissions" in record.meta) {
        let canProceed = false;
        let permissions = record.meta.permissions;
        let authPermissions = Cookie.get("type");

        // Allowed roles to access maintainer.
        if (authPermissions) {
          permissions.forEach((permission) => {
            if (canProceed === false && authPermissions.includes(permission)) {
              canProceed = true;
            }
          });
          if (!canProceed) {
            allowedToEnter = false;
            // redirect to not-authorized page
            next({
              path: "/not-authorized",
              replace: true,
            });
          }
        }
      }
    }
  });
  if (allowedToEnter) {
    // go to the requested page
    next();
  }
}

export default router;
